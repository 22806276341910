<template>
    <b-overlay :show="loading">
        <div class="d-flex justify-content-end mb-2">
            <template v-if="hasPermissionPay">
                <b-button @click.prevent="printEvidence" variant="primary">
                    <feather-icon icon="PrinterIcon"></feather-icon>
                    Cetak Pembayaran
                </b-button>
            </template>
            <template v-else>
                <b-button class="mr-1" @click.prevent="" variant="warning">
                    <feather-icon icon="UploadCloudIcon"></feather-icon>
                    Upload Bukti Pembayaran
                </b-button>
                <b-button v-if="this.isFinance" class="mr-1" @click.prevent="showongkir = true" variant="relief-primary">
                    <feather-icon icon="TruckIcon"></feather-icon>
                    Tentukan Ongkir
                </b-button>
        <b-modal centered id="showongkir" title="Tentukan Ongkir" v-model="showongkir" no-close-on-backdrop no-close-on-esc>
          <b-form-group label="Masukkan Ongkir"><b-input-group
      prepend="Rp"
      class="input-group-merge"
    >
            <b-form-input v-model="ongkir" @keyup="doFormatRupiah()"></b-form-input>
            </b-input-group>
          </b-form-group>
          <!-- <b-form-group label="Upload Bukti" >
            <b-form-file
      v-model="file"
      class="mt-1"
      plain
    />
    <div class="mt-1">
      Selected file: <strong>{{ file2 ? file2.name : '' }}</strong>
    </div>
          </b-form-group> -->

          <template #modal-footer>
            <b-button variant="primary" @click.prevent="simpanPengiriman">Simpan</b-button>
          </template>
        </b-modal>

                <b-button @click.prevent="print" variant="primary">
                    <feather-icon icon="PrinterIcon"></feather-icon>
                    Cetak Form Pemesanan
                </b-button>
            </template>
        </div>
        <b-card class="mb-2">
            <header class="d-flex justify-content-between align-items-center mb-1">
                <h4>{{ pembelian.no ? pembelian.no : '' }}</h4>
                <div class="d-flex align-item-center">
                    <p>Tanggal Pembelian: {{ humanDate(pembelian.tanggal) }}</p>
                    <div v-if="hasPermissionPay && pembelian && pembelian.izin == 1">
                        <b-button :disabled="pembelian.informasi.hutang < 1" variant="primary" class="ml-2" size="sm"
                            @click.prevent="$router.push(`/pembelian/bayar/${pembelian.id}`)">Input
                            Pembayaran</b-button>
                    </div>
                </div>
            </header>
            <b-row>
                <b-col sm="12" md="6" lg="4">
                    <span class="d-block">Pembuat</span>
                    <strong class="d-block mb-1">{{ pembelian.pembuat }}</strong>
                    <span class="d-block" v-if="pembelian.jatuh_tempo">Tanggal Jatuh Tempo</span>
                    <strong class="d-block mb-1" v-if="pembelian.jatuh_tempo">{{ humanDate(pembelian.jatuh_tempo)
                        }}</strong>
                    <span class="d-block">Keterangan</span>
                    <strong class="d-block mb-2">{{ pembelian.keterangan }}</strong>
                    <b-badge class="mb-1" variant="success" v-if="pembelian.informasi.hutang < 1">Lunas</b-badge>
                    <b-badge class="mb-1" variant="danger" v-else>Belum Lunas</b-badge>
                </b-col>
                <b-col sm="12" md="6" lg="4" v-if="!hasPermissionPay">
                    <span class="d-block mb-1">Informasi Supplier</span>
                    <strong class="d-block mb-1">Instansi: {{ pembelian.instansi }}</strong>
                    <strong class="d-block mb-1">Email: {{ pembelian.email }}</strong>
                    <strong class="d-block mb-1">No. HP: {{ pembelian.nohp }}</strong>
                </b-col>
                <b-col sm="12" md="6" lg="4" v-if="pembelian.informasi">
                    <span class="d-block mb-1">Informasi Pembayaran</span>
                    <strong class="d-block mb-1">Total Bayar: {{ formatRupiah(pembelian.informasi.total_bayar) }}</strong>
                    <strong class="d-block mb-1">Total Pembelian:
                        {{ formatRupiah(pembelian.informasi.total_pembelian) }}</strong>
                    <strong class="d-block mb-1">Total Ongkir: {{ formatRupiah(pembelian.informasi.total_bayar) }} <b-button v-if="this.isFinance" size="sm" @click.prevent="showongkir = true" variant="warning">
                    <feather-icon icon="TruckIcon"></feather-icon>
                    Ubah Ongkir
                </b-button></strong> 
                
                    <!-- <strong class="d-block mb-1">Hutang: {{ formatRupiah(pembelian.informasi.hutang) }}</strong> -->
                </b-col>
            </b-row>
        </b-card>
        <h4 class="mb-1">Rincian Barang <span v-if="pembelian.rincian && pembelian.rincian.length > 0"> ({{
        pembelian.rincian.length }})</span></h4>
        <b-card class="mb-2">
            <b-table responsive :fields="fields" :items="pembelian.rincian">
                <template #cell(barang)="{ item }">
                    <span v-if="item.barang">
                        {{ item.barang.nama }}
                    </span>
                    <i v-else class="text-danger">Barang tidak ada.</i>
                </template>
                <template #cell(qty)="{ item }">
                    <div>{{ item.qty }} /{{ item.barang && item.barang.satuan ? item.barang.satuan.satuan.toLowerCase()
        : '' }}</div>

                </template>
                <template #cell(diskon)="{ item }">
                    {{ formatRupiah(item.diskon) }}
                </template>
                <template #cell(harga_beli)="{ item }">
                    {{ formatRupiah(item.harga_beli) }}
                </template>
                <template #cell(total)="{ item }">
                    {{ formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty)) }}
                </template>
            </b-table>
            <div class="d-flex justify-content-end align-items-center">
                <h4>Total Pembelian: </h4>
                <h2 class="ml-2">Rp {{ pembelian.informasi ? formatRupiah(pembelian.informasi.total_pembelian) : '' }}
                </h2>
            </div>
        </b-card>
        <section class="d-flex align-items-center justify-content-end">
            <div v-if="hasPermissionApprove && pembelian">
                <b-button variant="success" v-if="pembelian.izin == 0" @click.prevent="approve">Approve
                    Pembelian</b-button>
            </div>
            <div v-if="hasPermissionCancel && pembelian" class="ml-1">
                <b-button variant="outline-danger" v-if="pembelian.izin == 0" @click.prevent="cancel">Batalkan
                    Pembelian</b-button>
            </div>
        </section>

    </b-overlay>
</template>
<script>
import { BModal, BInputGroup, BFormGroup, BFormInput, BCard, BTable, BBadge, BRow, BCol, BButton, BOverlay } from 'bootstrap-vue'
export default {
    components: {
        BModal, BInputGroup, BFormGroup, BFormInput, BCard, BTable, BBadge, BRow, BCol, BButton, BOverlay
    },
    data: () => ({
        showongkir: false,
        ongkir: 0,
        listStatus: [],
        loading: false,
        pembelian: {},
        fields: [
            {
                key: 'no_container',
                label: 'No. Container'
            },
            {
                key: 'no_seal',
                label: 'No. Seal'
            },
            {
                key: 'barang',
                label: 'Nama Barang'
            },
            {
                key: 'qty',
                label: 'Qty'
            },
            {
                key: 'diskon',
                label: 'Diskon'
            },
            {
                key: 'harga_beli',
                label: 'Harga Beli'
            },
            {
                key: 'total',
                label: 'Total'
            }
        ],
        hasPermissionApprove: false,
        hasPermissionPay: false,
        hasPermissionCancel: false,
        confirmOption: {
            title: 'Setujui Pembelian ini?',
            text: `Dengan ini finance dapat meng-inputkan pembayaran pembelian`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false
        }
    }),
    methods: {
        doFormatRupiah() {
      this.ongkir = this.formatRupiah(this.ongkir);

        },
        async printEvidence() {
            try {
                this.loading = true
                const response = await this.$store.dispatch('pembelian/printPembayaran', this.$route.params.id)
                this.loading = false
                window.open(URL.createObjectURL(response))
            }
            catch (e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async print() {
            try {
                this.loading = true
                const response = await this.$store.dispatch('pembelian/print', this.$route.params.id)
                this.loading = false
                window.open(URL.createObjectURL(response))
            }
            catch (e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async doUpdate(payload) {
            try {
                const actionName = payload.tolak == 1 ? 'pembelian/cancel' : 'pembelian/save'
                await this.$store.dispatch(actionName, [payload])
                const params = {
                    order: 'desc'
                }
                if (this.isGM || this.isOwner) params.jenis = 2
                await this.$store.dispatch('pembelian/getData', params)

                this.getPembelian()

                return Promise.resolve()
            }
            catch (e) {
                this.displayError(e)
                return Promise.reject(e)
            }
        },
        approve() {
            this.confirmOption.title = 'Setujui Pembelian ini?'
            this.confirmOption.text = 'Dengan ini finance dapat meng-inputkan pembayaran pembelian'
            this.$swal(this.confirmOption)
                .then(async res => {
                    if (res.value) {
                        const payload = {
                            id: this.$route.params.id,
                            id_akun: this.pembelian.id_akun,
                            jenis: this.isGM || this.isOwner ? 2 : 1,
                            izin: 1
                        }
                        this.doUpdate(payload)
                            .then(() => {
                                this.displaySuccess({
                                    message: 'Pembelian berhasil disetujui'
                                })
                            })
                    }
                })
        },
        cancel() {
            this.confirmOption.title = 'Batalkan Pembelian ini?'
            this.confirmOption.text = 'Anda akan membatalkan pembelian ini'
            this.$swal(this.confirmOption)
                .then(async res => {
                    if (res.value) {
                        const payload = {
                            id: this.$route.params.id,
                            jenis: this.isGM ? 2 : 1,
                            tolak: 1
                        }
                        this.doUpdate(payload)
                            .then(() => {
                                this.displaySuccess({
                                    message: 'Pembelian berhasil ditolak'
                                })
                            })
                    }
                })
        },
        getPembelian() {
            const pembelians = this.$store.state.pembelian.datas
            if (!pembelians) {
                this.$router.push('/pembelian')
            }

            const pembelian = pembelians.find(item => item.id == this.$route.params.id)
            if (!pembelian) {
                this.$router.push('/pembelian')
            }

            this.pembelian = pembelian
        },
        async getStatusApprove() {
            const menu = await this.currentMenu(this.$route.meta.parent)
            const params = {
                level_id: this.user.level.id
            }
            if (menu) {
                params.menu_id = menu.id
            }
            this.listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionApprove = this.listStatus.some(status => status.data_status == 1)
            const hasPermissionPay = this.listStatus.some(status => status.data_status == 2)
            const hasPermissionCancel = this.listStatus.some(status => status.data_status == 3)

            this.hasPermissionApprove = hasPermissionApprove
            this.hasPermissionPay = hasPermissionPay
            this.hasPermissionCancel = hasPermissionCancel
        }
    },
    computed: {
        canPay() {
            if (this.pembelian.informasi && this.pembelian.informasi.total_bayar) {
                const { total_bayar, total_pembelian } = this.pembelian.informasi
                return this.pembelian.izin == 1 && (total_bayar < total_pembelian)
            }
            return false
        }
    },
    async created() {
        await this.getPembelian()
        await this.getStatusApprove()
    }
}
</script>